<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">店铺名片</div>
		</div>
		<div class="canvas-sec" v-show="false">
			<canvas id='mycanvas' class="mycanvas share-img" />
			<img src="../../assets/shop/canvas-bg.png" ref='bg' crossorigin="anonymous" class="bg" />
			<img :src="qrcodeImg" ref='qrcodeimg' crossorigin="anonymous" class="qrcode-img" />
		</div>
		<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
		<!-- <div ref="qrCodeDiv"></div> -->
		<img v-show="base64_img" :src="base64_img" class="share-img" alt="">
		<img v-show="base64_img" src="../../assets/shop/save-btn.png" class="save-btn" alt=""
			@click.stop="downloadImg()">
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import QRCode from 'qrcodejs2';
	export default {
		data() {
			return {
				shop: {},
				qrcodeImg: '',
				base64_img: ''
			}
		},
		methods: {
			createAntiqueShopCard() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/antiqueShop/createAntiqueShopCard', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					console.log(response)
					if (response.data.code == 0) {
						console.log(1)
						that.shop = response.data.obj;
						let qrcode = new QRCode(that.$refs.qrCodeDiv, {
							text: config.tdUrl + '/?id=' + response.data.obj.id,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#FFFFFF", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
						let canvas = qrcode._el.querySelector(
							"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						that.qrcodeImg = canvas.toDataURL("image/png");
						console.log(that.qrcodeImg)
						setTimeout(() => {
							that.makeCanvas();
						}, 100)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			makeCanvas() {
				const that = this;
				var qrcodeimg = that.$refs.qrcodeimg;
				var bg = that.$refs.bg;
				var c = document.getElementById("mycanvas");
				var ctx = c.getContext("2d");
				c.width = 651;
				c.height = 1339;
				ctx.fillStyle = '#000000';
				ctx.fillRect(0, 0, 651, 1339);

				let name = that.shop.name;
				ctx.font = "50px Arial";
				let nameWidth = ctx.measureText(name).width;
				console.log(nameWidth)
				ctx.font = "26px Arial";
				let pos = that.shop.productEntryNo
				let posWidth = ctx.measureText(pos).width;
				posWidth = posWidth + 16 //背景宽度
				let rbase = 14
				let r = rbase / 2
				let nameX = (651 - nameWidth - posWidth - 10) / 2;
				let nameY = 690;
				let posX = nameX + nameWidth + 10 + 8; //#7/155的X坐标
				let posY = 706; //#7/155的Y坐标
				let rectX = nameX + nameWidth + 10 //编号白色背景左上角X坐标
				let rectY = 708 - 2; //编号白色背景左上角Y坐标
				let rectRightX = rectX + posWidth; //编号白色背景右上角X坐标
				console.log(posWidth)
				if (bg.complete) {
					console.log('bg.complete')
					ctx.drawImage(bg, 0, 0, 651, 1339);
					ctx.drawImage(qrcodeimg, 100, 1028, 207, 207);
					ctx.fillStyle = '#FFFFFF';
					ctx.font = "50px Arial";
					ctx.textAlign = "left";
					ctx.textBaseline = "middle";
					ctx.fillText(name, nameX, nameY + 70 / 2);



					ctx.beginPath();
					ctx.fillStyle = '#FFFFFF';
					ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(rectRightX - r, rectY + 34 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					// ctx.lineTo(rectX, rectY); //左下角
					// ctx.lineTo(rectRightX, rectY); //左下角
					// ctx.lineTo(rectRightX, rectY+34); //左下角
					ctx.lineTo(rectX, rectY + 34); //左下角
					ctx.closePath()
					ctx.fill()


					ctx.fillStyle = '#907560';
					ctx.font = "26px Arial";
					ctx.fillText(pos, posX, posY + 38 / 2);
					setTimeout(function() { //兼容苹果手机，延时函数
						Toast.clear()
						var base64_img = c.toDataURL("image/png");
						that.base64_img = base64_img
					}, 100)
				}

				bg.onload = function() {
					ctx.drawImage(bg, 0, 0, 651, 1339);
					ctx.drawImage(qrcodeimg, 100, 1028, 207, 207);
					ctx.fillStyle = '#FFFFFF';
					ctx.font = "50px Arial";
					ctx.textAlign = "left";
					ctx.textBaseline = "middle";
					ctx.fillText(name, nameX, nameY + 70 / 2);



					ctx.beginPath();
					ctx.fillStyle = '#FFFFFF';
					ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(rectRightX - r, rectY + 34 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					// ctx.lineTo(rectX, rectY); //左下角
					// ctx.lineTo(rectRightX, rectY); //左下角
					// ctx.lineTo(rectRightX, rectY+34); //左下角
					ctx.lineTo(rectX, rectY + 34); //左下角
					ctx.closePath()
					ctx.fill()


					ctx.fillStyle = '#907560';
					ctx.font = "26px Arial";
					ctx.fillText(pos, posX, posY + 38 / 2);
					setTimeout(function() { //兼容苹果手机，延时函数
						Toast.clear()
						var base64_img = c.toDataURL("image/png");
						that.base64_img = base64_img
					}, 100)
				}
			},
			downloadImg() {
				const that = this
				if (util.isWx()) {
					Toast('请长按图片保存')
				} else {
					// downloadCanvas.downloadCanvas('mycanvas', {
					// 	name: 'mycanvas',
					// 	type: 'jpg',
					// 	quality: 0.7
					// });
					// let a = document.createElement('a')
					// let event = new MouseEvent('click')
					// a.download = 'default.png'
					// a.href = this.base64_img
					// a.dispatchEvent(event)

					// let base64Str =this.base64_img;
					// let aLink = document.createElement("a");
					// aLink.style.display = "none";
					// aLink.href = base64Str;
					// aLink.download = "test.jpg";
					// // 触发点击-然后移除
					// document.body.appendChild(aLink);
					// aLink.click();
					// document.body.removeChild(aLink);
					var aLink = document.createElement('a');
					var blob = this.base64ToBlob(this.base64_img); //new Blob([content]);
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
					aLink.download = 'shop(' + that.shop.productEntryNo + ').png';
					aLink.href = URL.createObjectURL(blob);
					// aLink.dispatchEvent(evt);
					aLink.click()
					Dialog({
						message: '若下载失败请长按图片保存'
					})
				}
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}
		},
		mounted() {
			Toast.loading({
				message: '正在生成店铺名片',
				forbidClick: true,
				duration: 0,
			});
			this.createAntiqueShopCard();
		}
	}
</script>

<style scoped="scoped">
	.container {
		background: url('../../assets/shop/bg.jpg');
		background-size: 750px 1448px;
		background-repeat: repeat;
		min-height: 100%;
		padding-bottom: 50px;
	}

	.bg {
		width: 651px;
		height: 1339px;
	}

	.share-img {
		width: 651px;
		height: 1339px;
		display: block;
		margin: 68px auto;
	}

	.save-btn {
		width: 437px;
		height: 109px;
		display: block;
		margin: 65px auto;
	}
</style>
